import React from "react";
import { useFieldArray } from "react-hook-form";

export default function Fields({ control, register, setValue, getValues }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "extra_items"
  });

  return (
    <>
      {/* <div className="counter"> Render Count: {renderCount}</div> */}
      <ul>
        {fields.map((item, index) => {
          return (
            <li key={item.id}>
              <div class="field has-addons">
                <div class="field control has-icons-left has-icons-right is-expanded">
                  <input class="input is-rounded"
                    {...register(`extra_items.${index}.name`)}
                    defaultValue={item.name}
                  />
                  <span class="icon is-medium is-left">
                        <i class="fas fa-gift"></i> 
                  </span>                  
                </div>
                <p class="control">
                  <button type="button" onClick={() => {
                    remove(index);
                  }}>[-]</button> 
                </p>
              </div>
            </li>
          );
        })}
      </ul>
      <button type="button" onClick={() => {
          if ((getValues().extra_items || []).length < 4){
            append({ name: "  " })
          } else {
            alert('Maximum 5 items per gift request!')
          }
        }
      }> [+] Add More Item</button>        
    </>
  );
}
