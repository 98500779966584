import React from "react";
import { useForm } from "react-hook-form";
import GiftItemArray from "./GiftItemArray";

const defaultValues = {
  test: [
  ]
};

function GiftItemForm() {
  const {
    control,
    register,
    getValues,
    errors,
    setValue
  } = useForm({
    defaultValues
  });
  return (
     <div>
      <GiftItemArray
        {...{ control, register, defaultValues, getValues, setValue, errors }}
      />
    </div>
    
  );
}

export default GiftItemForm
