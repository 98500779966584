// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Noty = require("noty")
// window.Dropzone = require("dropzone")
window.BulmaCarousel = require("bulma-extensions/bulma-carousel/dist/js/bulma-carousel")


$(document).on('turbolinks:load', () => {
    $('.toggle').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        $('#' + e.target.getAttribute('aria-controls')).toggleClass('is-hidden');
    });

    // $('#check-agree-to-tos').on('change', (e) => {
    //     e.stopPropagation();
    //     e.preventDefault();
    //     if ($('#check-agree-to-tos').is(":checked")){
    //         $("#gift-send-button").prop("disabled", false);
    //     } else {
    //         $("#gift-send-button").prop("disabled", true);
    //     }
    // });

    $('#gift-url-input').on('change', (e) => {
        e.stopPropagation();
        e.preventDefault();
        // fetch the product

        $("#fetch-gift-url").addClass('is-medium is-loading');
        $("#gift-send-button").prop("disabled", true);
        //$("#fetched-product-info").addClass('is-hidden');

        //reset gift info
        $("#gift-thumbnail-url").html("<img src='/gift-loading.gif'>");
        $("#gift-unit-price").text("");
        $("#gift-unit-title").text("");

        // Call
        $.ajax({

            // URL you want to get
            url: '/gifts/fetch?url=' + $("#gift-url-input").val(),

            // Set a realistic time in milliseconds
            timeout: 20000,

            // Put in success callback function here, this example
            // shows you the data you got back from the call
            success: function (data) {
                if (data.thumbnail_url != null && data.thumbnail_url != "" && data.title != null) {

                    // sender view
                    $("#gift-thumbnail-url").html("<img src=" + data.thumbnail_url + ">");
                    $("#gift-unit-title").text(data.title);

                    // receiver view

                    if (!$('#hide-gift').is(":checked")) {
                        $("#gift-thumbnail-url-recv").html("<img src=" + data.thumbnail_url + ">");
                        $("#gift-unit-title-recv").text(data.title);
                    }

                    if (data.price != null) {
                        $("#gift-unit-price").text('$' + data.price);
                    }

                    $("#fetched-product-info").removeClass('is-hidden');

                    $("#hidden-gift-thumbnail-url").val(data.thumbnail_url);
                    $("#hidden-gift-unit-price").val(data.price);
                    $("#hidden-gift-unit-title").val(data.title);
                } else {
                    $("#gift-thumbnail-url").html("<img src='/box.png' width='50%'>");
                }

                $("#fetch-gift-url").removeClass('is-loading');

                if ($('#check-agree-to-tos').is(":checked")) {
                    $("#gift-send-button").prop("disabled", false);
                } else {
                    $("#gift-send-button").prop("disabled", true);
                }
            },

            // Put in an error handling function, just an alert in this case
            error: function (badData) {
                $("#fetch-gift-url").removeClass('is-loading');
                $("#gift-thumbnail-url").html("<img src='/box.png' width='50%'>");
                if ($('#check-agree-to-tos').is(":checked")) {
                    $("#gift-send-button").prop("disabled", false);
                } else {
                    $("#gift-send-button").prop("disabled", true);
                }
            },

            type: 'GET'
        });
    });

    $('.reveal-toggle').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        // fetch the product
        $('#' + e.target.getAttribute('aria-controls')).toggleClass('is-hidden');
        $("#default-gift-info-card").toggleClass('is-hidden');
    });

    $('.tab-give').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        $('.gift-index-tab').removeClass('is-active');
        $('.gift-table').addClass('is-hidden');
        $('.tab-give').addClass('is-active');
        $('.table-give').removeClass('is-hidden');
    });


    $('.tab-receive').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        $('.gift-index-tab').removeClass('is-active');
        $('.gift-table').addClass('is-hidden');
        $('.tab-receive').addClass('is-active');
        $('.table-receive').removeClass('is-hidden');
    });


    $('.tab-all-items').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        $('.gift-index-tab').removeClass('is-active');
        $('.gift-table').addClass('is-hidden');
        $('.tab-all-items').addClass('is-active');
        $('.table-all-items').removeClass('is-hidden');
    });


    $('.tab-your-network').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        $('.gift-index-tab').removeClass('is-active');
        $('.gift-table').addClass('is-hidden');
        $('.tab-your-network').addClass('is-active');
        $('.table-your-network').removeClass('is-hidden');
    });


    $('.new-gift-tab').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        $('.new-gift-tab').toggleClass('is-active');
        $('.new-gift-view').toggleClass('is-hidden');
    });

    $('#hide-gift').on('change', (e) => {
        if ($('#hide-gift').is(":checked")) {
            $("#gift-thumbnail-url-recv").html("<img src='/box.png' width='50%'>");
            $("#gift-unit-title-recv").text("");
        } else {
            $("#gift-thumbnail-url-recv").html("<img src=" + document.getElementById('hidden-gift-thumbnail-url').value + ">");
            $("#gift-unit-title-recv").text(document.getElementById('hidden-gift-unit-title').value);
        }
    });
})

/*
 * When the gift-url-input is pre-filled, we want to trigger a change
 * event so that the product info will be fetched.  We delay that action 
 * until 2 secs later. 
 */
window.setTimeout(() => {
    if ($('#gift-url-input').val() && $('#gift-url-input').val().length !== 0 &&  // default gift link
        (!$('#gift-unit-title').val() || $('#gift-unit-title').val().length == 0)) { // don't fetch if it's a product already
        $('#gift-url-input').trigger('change');
    }
}, 2000);

window.interdeal = {
    "sitekey": "7f23b5ff743d6be888a58980641aa8b6",
    "Position": "Left",
    "Menulang": "EN",
    "domains": {
        "js": "https://cdn.equalweb.com/",
        "acc": "https://access.equalweb.com/"
    },
    "btnStyle": {
        "vPosition": [
            "80%",
            null
        ],
        "scale": [
            "0.8",
            "0.8"
        ],
        "icon": {
            "type": 7,
            "shape": "semicircle",
            "outline": false
        },
        "color": {
            "main": "#2e850f",
            "second": ""
        }
    }
};

(function (doc, head, body) {
    var coreCall = doc.createElement('script');
    coreCall.src = 'https://cdn.equalweb.com/core/2.1.10/accessibility.js';
    coreCall.defer = true;
    coreCall.integrity = 'sha512-PWvjZ/e9EGeHEPppEOqscViKxQIW1/5rJeFedXLJiv0IrltJPvuhrYOiUsOkQ49FaZ5HSDp51/O/6V9uL2nNIA==';
    coreCall.crossOrigin = 'anonymous';
    coreCall.setAttribute('data-cfasync', true);
    body ? body.appendChild(coreCall) : head.appendChild(coreCall);
})(document, document.head, document.body);


require("trix")
require("@rails/actiontext")
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
